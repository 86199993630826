<template>
    <div class="volunteer-box">
        <div class="title-head">学生信息</div>
        <div class="title-info">
            <i class="red">*</i>如信息有误，请联系北京航空航天大学招生工作办公室进行修改。(办公室电话：010-83429695)
        </div>
        <div class="user-info">
            <div class="user-info-item">报名号：{{dataInfo.account.bm_num}}</div>
            <div class="user-info-item">申请大学：{{dataInfo.account.university_name}}</div>
            <div class="user-info-item">姓名：{{dataInfo.account.name}}</div>
            <div class="user-info-item">身份证号：{{dataInfo.account.sfz}}</div>
            <div class="user-info-item">省份：{{dataInfo.account.province}}</div>
            <div class="user-info-item">中学：{{dataInfo.account.school}}</div>
            <div class="user-info-item">
                考试科目：{{dataInfo.account.subject1}}{{dataInfo.account.subject2?'、'+dataInfo.account.subject2:''}}{{dataInfo.account.subject3?'、'+dataInfo.account.subject3:''}}
            </div>
        </div>
        <div class="line-de"></div>

        <div class="title-head">考点省市选择</div>
        <div class="title-info">
            <div style="color: #e0823d;"><i class="red">*</i>请谨慎选择，一旦提交不接受修改。</div>
            <div><i class="red">*</i>全国共设14个考点城市，请用鼠标拉动滚动条就近选择考点城市。</div>
            <div><i class="red">*</i>请按个人意向填写希望在何省市参加初试，校方将会根据你的选择情况安排考位。</div>
        </div>
        <div class="selectPC flex-row">
            <div class="selectPC-title">考点省市：</div>
            <el-select v-model="dataInfo.province"
                       placeholder="请选择省份"
                       ref="provinceSelect"
                       @change="value=>changeProvince(value, true)">
                <el-option
                        v-for="(item, index) in provinceList"
                        :key="index"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="dataInfo.city"
                       ref="citySelect"
                       placeholder="请选择城市">
                <el-option
                        v-for="(item, index) in cityList"
                        :key="index"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="line-de"></div>

        <div class="title-head">报名缴费（{{dataInfo.amount}} 元/人）</div>
        <div class="pay-type flex-row">
            <div class="pay-type-item"
                 :class="pay_way==1?'is-select':''"
                 @click="setPayWay(1)"
            >
                <img src="../../assets/images/wx.png">
            </div>
            <div class="pay-type-item"
                 :class="pay_way==2?'is-select':''"
                 @click="setPayWay(2)"
            >
                <img src="../../assets/images/zfb.png">
            </div>
        </div>
        <div class="pay-btn">
            <el-button @click="onPay">立即支付</el-button>
        </div>

        <div class="notice_area">
            <div class="notice_area_title">
                <i>*</i>
                注意事项
            </div>
            <ul>
                <li>1. 考生须填写考点省市且完成缴费后，方可视为有效报名。</li>
                <li>2. 支付完成后请不要立即关闭浏览器，等待支付完成返回本页面，以确定支付完成。</li>
                <li>3. 支付确认后，<span>考点省市不可更改</span>，请在支付前仔细检查。</li>
            </ul>
        </div>

        <div class="material"></div>

        <WxPay ref="wxPay"
               :dialogVisibleFu="dialogVisibleWx"
               :qrUrlFu="qrUrlWx"
               @closeDialog="closeDialogWx"/>
    </div>
</template>

<script>
    import WxPay from "c/index/WxPay";
    import {getConfig, confirmVolunteer, getPay, checkPay} from "r/index/index.js";

    export default {
        name: "Volunteer",
        components: {WxPay},
        data() {
            return {
                loading: false,
                dataInfo: {
                    account: {},
                    all_province: [],
                    all_city: [],
                    amount: null,
                    school_name: null,
                    show_school: null,
                    province: null,
                    city: null,
                },
                provinceList: [],
                cityList: [],
                pay_way: null,
                timeInterval: null,
                qrUrlWx: null,
                dialogVisibleWx: false,
            }
        },
        created() {
            this.getConfig();
        },
        mounted() {
            this.$nextTick(() => {
                this.$refs.provinceSelect.$refs.scrollbar.$el.classList.add(
                    "scroll-opacity"
                );
                this.$refs.citySelect.$refs.scrollbar.$el.classList.add(
                    "scroll-opacity"
                );
            });
        },
        methods: {
            closeDialogWx(val) {
                this.dialogVisibleWx = val;
            },
            async getConfig() {
                this.$store.commit('setAllLoading', true);
                let {data: res} = await getConfig({})
                // console.log(res)
                if (res.code != 200) {
                    this.$store.commit('setAllLoading', false);
                    return this.$message.error(res.message);
                }
                let my_volunteer = res.data.my_volunteer;
                this.dataInfo = {
                    account: res.data.account,
                    all_province: res.data.all_province,
                    all_city: res.data.all_city,
                    amount: res.data.amount,
                    school_name: res.data.school_name,
                    show_school: res.data.show_school,
                    province: my_volunteer && my_volunteer['volunteer_p1'] ? Number(my_volunteer['volunteer_p1']) : null,
                    city: my_volunteer && my_volunteer['volunteer_c1'] ? Number(my_volunteer['volunteer_c1']) : null,
                }
                this.provinceList = this.dataInfo.all_province;
                this.changeProvince(this.dataInfo.province, false);
                this.$store.commit('setAllLoading', false);
            },
            // 改变省份，获取城市列表
            changeProvince(val, type) {
                if (type) {
                    this.dataInfo.city = null;
                }
                let all_city = this.dataInfo.all_city;
                let city_list = [];
                all_city.forEach((item, index) => {
                    if (val == item.parent_id) {
                        city_list.push(item);
                    }
                })
                this.cityList = city_list;
            },
            // 设置支付方式
            setPayWay(val) {
                this.pay_way = val;
            },
            // 立即支付按钮
            async onPay() {
                if (!this.dataInfo.province || this.dataInfo.province == '0') {
                    return this.$message.info('请选择考点省市！');
                }
                if (!this.dataInfo.city || this.dataInfo.city == '0') {
                    return this.$message.info('请选择考点城市！');
                }
                if (!this.pay_way) {
                    return this.$message.info('请选择支付方式！');
                }
                if (this.loading) {
                    this.$message.info('操作太频繁了，请稍后重试');
                    return false;
                }
                this.loading = true;
                let {data: res} = await confirmVolunteer({
                    bm_num: this.dataInfo.account.bm_num,
                    volunteer_p1: this.dataInfo.province,
                    volunteer_c1: this.dataInfo.city,
                })
                // console.log(res)
                if (res.code != 200) {
                    this.loading = false;
                    return this.$message.error(res.message);
                }
                await this.getPay()
            },
            // 获取支付配置
            async getPay() {
                let {data: res} = await getPay({
                    bm_num: this.dataInfo.account.bm_num,
                    pay_way: this.pay_way,
                })
                // console.log(res)
                if (res.code != 200) {
                    this.loading = false;
                    return this.$message.error(res.message);
                }
                this.loading = false;
                if (this.pay_way == 1) {
                    this.qrUrlWx = res.data.code_url;
                    this.dialogVisibleWx = true;
                } else {
                    let form = res.data;
                    let div = document.createElement("div");
                    div.innerHTML = form;
                    document.body.appendChild(div);
                    document.forms[0].submit();
                    document.body.removeChild(div);
                }
                this.timeInterval = setInterval(() => {
                    this.checkPay();
                }, 5000)
            },
            // 检查是否支付
            async checkPay() {
                let {data: res} = await checkPay({
                    bm_num: this.dataInfo.account.bm_num,
                })
                if (res.code != 200) {
                    return false;
                }
                this.clearTimeInterval();
                if (this.pay_way == 1) {
                    this.$refs.wxPay.cancelBtn();
                }
                this.$emit('setStatus', 6);
            },
            // 清除定时器
            clearTimeInterval() {
                this.timeInterval && clearInterval(this.timeInterval);
                this.timeInterval = null;
            }
        },
        destroyed() {
            this.clearTimeInterval();
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/base.scss";
</style>

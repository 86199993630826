<template>
    <div class="volunteer-box">
        <div class="download_area flex-row">
            <div>
                <div class="download_info">
                    <i class="red">*</i>考点省市选择已确定，缴费已完成。考试及考点具体信息以准考证上显示为准。
                </div>
                <div class="download_info">
                    <i class="red">*</i>考试日期为6月12日，请考生于6月5日00:00后登录本网站自行下载并打印准考证。
                </div>
            </div>
            <el-button @click="download">下载准考证</el-button>
        </div>
        <div class="line-de"></div>
        <div class="title-head">学生信息</div>
        <div class="title-info">
            <i class="red">*</i>如信息有误，请联系北京航空航天大学招生工作办公室进行修改。(办公室电话：010-83429695)
        </div>
        <div class="user-info">
            <div class="user-info-item">报名号：{{dataInfo.account.bm_num}}</div>
            <div class="user-info-item">申请大学：{{dataInfo.account.university_name}}</div>
            <div class="user-info-item">姓名：{{dataInfo.account.name}}</div>
            <div class="user-info-item">身份证号：{{dataInfo.account.sfz}}</div>
            <div class="user-info-item">省份：{{dataInfo.account.province}}</div>
            <div class="user-info-item">中学：{{dataInfo.account.school}}</div>
            <div class="user-info-item">
                考试科目：{{dataInfo.account.subject1}}{{dataInfo.account.subject2?'、'+dataInfo.account.subject2:''}}{{dataInfo.account.subject3?'、'+dataInfo.account.subject3:''}}
            </div>
        </div>
        <div class="line-de"></div>

        <div class="title-head">考点省市选择</div>
        <div class="title-info">
            <div style="color: #e0823d;"><i class="red">*</i>请谨慎选择，一旦提交不接受修改。</div>
            <div><i class="red">*</i>全国共设14个考点城市，请用鼠标拉动滚动条就近选择考点城市。</div>
            <div><i class="red">*</i>请按个人意向填写希望在何省市参加初试，校方将会根据你的选择情况安排考位。</div>
        </div>
        <div class="selectPC flex-row">
            <div class="selectPC-title">考点省市：</div>
            <el-select v-model="dataInfo.province"
                       placeholder="请选择省份"
                       @change="value=>changeProvince(value, true)"
                       :disabled="true">
                <el-option
                        v-for="(item, index) in provinceList"
                        :key="index"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="dataInfo.city"
                       placeholder="请选择城市"
                       :disabled="true">
                <el-option
                        v-for="(item, index) in cityList"
                        :key="index"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
    import {getConfig} from "r/index/index.js";

    export default {
        name: "MyVolunteer",
        data() {
            return {
                dataInfo: {
                    account: {},
                    all_province: [],
                    all_city: [],
                    amount: null,
                    school_name: null,
                    show_school: null,
                    province: null,
                    city: null,
                },
                provinceList: [],
                cityList: [],
            }
        },
        created() {
            this.getConfig();
        },
        methods: {
            // 获取配置信息
            async getConfig() {
                this.$store.commit('setAllLoading', true);
                let {data: res} = await getConfig({})
                // console.log(res)
                if (res.code != 200) {
                    this.$store.commit('setAllLoading', false);
                    return this.$message.error(res.message);
                }
                let my_volunteer = res.data.my_volunteer;
                this.dataInfo = {
                    account: res.data.account,
                    all_province: res.data.all_province,
                    all_city: res.data.all_city,
                    amount: res.data.amount,
                    school_name: res.data.school_name,
                    show_school: res.data.show_school,
                    province: my_volunteer && my_volunteer['volunteer_p1'] ? Number(my_volunteer['volunteer_p1']) : null,
                    city: my_volunteer && my_volunteer['volunteer_c1'] ? Number(my_volunteer['volunteer_c1']) : null,
                }
                this.provinceList = this.dataInfo.all_province;
                this.changeProvince(this.dataInfo.province, false);
                this.$store.commit('setAllLoading', false);
            },
            // 改变省份，获取城市列表
            changeProvince(val, type) {
                if (type) {
                    this.dataInfo.city = null;
                }
                let all_city = this.dataInfo.all_city;
                let city_list = [];
                all_city.forEach((item, index) => {
                    if (val == item.parent_id) {
                        city_list.push(item);
                    }
                })
                this.cityList = city_list;
            },
            // 下载准考证按钮
            async download() {
                let {data: res} = await getConfig({})
                if (res.code != 200) {
                    return this.$message.error(res.message);
                }
                if (!res.data.pdf_time || res.data.pdf_time != 1) {
                    return this.$message.error('6月5日00:00后开放下载！');
                }
                await this.$router.push({
                    path: '/main/pdf',
                    query: {
                        bm_num: this.dataInfo.account.bm_num,
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/scss/base.scss";
</style>
